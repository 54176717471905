import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

export default function Review({
  firstName,
  lastName,
  address,
  suburb,
  postcode,
  phonenumber,
  checkboxState,
  otherRequirements,
}) {
  const selectedServices = Object.keys(checkboxState).filter(
    (key) => checkboxState[key]
  );

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Summary
      </Typography>
      <List disablePadding>
        {selectedServices.map((service) => (
          <ListItem key={service} sx={{ py: 0.25, px: 0 }}>
            <ListItemText primary={service} />
          </ListItem>
        ))}
      </List>
      {otherRequirements && (
        <Box>
          <Typography variant="h6" gutterBottom mt={2}>
            Other Requirements
          </Typography>
          <Typography gutterBottom>{otherRequirements}</Typography>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Your Details
          </Typography>
          <Typography gutterBottom>
            {firstName} {lastName}
          </Typography>
          <Typography gutterBottom>{phonenumber}</Typography>
          <Typography gutterBottom>
            {address}
            {", "}
            {suburb}
            {", "}
            {postcode}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
