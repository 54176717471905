import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default function AddressForm({ setFirstName, setLastName, setAddress, setSuburb, setPostcode, setPhonenumber }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Your Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="suburb"
            name="suburb"
            label="Suburb"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            onChange={(e) => setSuburb(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Postcode"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            onChange={(e) => setPostcode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="phonenumber"
            name="phonenumber"
            label="Phone Number"
            fullWidth
            autoComplete="phonenumber"
            variant="standard"
            onChange={(e) => setPhonenumber(e.target.value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
