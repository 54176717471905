import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";

export default function PaymentForm({
  checkboxState,
  setOtherRequirements,
  handleCheckboxChange,
}) {
  const [showingOther, setShowingOther] = useState(false);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Services
      </Typography>
      <Grid container spacing={3}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxState["Lawn Care"]}
                onChange={() => handleCheckboxChange("Lawn Care")}
              />
            }
            label="Lawn Care"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxState["Weeding"]}
                onChange={() => handleCheckboxChange("Weeding")}
              />
            }
            label="Weeding"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxState["Pruning and Trimming"]}
                onChange={() => handleCheckboxChange("Pruning and Trimming")}
              />
            }
            label="Pruning and Trimming"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxState["Fertilizing"]}
                onChange={() => handleCheckboxChange("Fertilizing")}
              />
            }
            label="Fertilizing"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxState["Mulching"]}
                onChange={() => handleCheckboxChange("Mulching")}
              />
            }
            label="Mulching"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxState["Other"]}
                onChange={(e) => {
                  if (showingOther) {
                    setShowingOther(false);
                  } else {
                    setShowingOther(true);
                  }
                }}
              />
            }
            label="Other"
          />
        </Grid>
      </Grid>
      {showingOther && (
        <TextField
          id="outlined-multiline-static"
          label="Please describe requirements."
          multiline
          rows={4}
          fullWidth
          sx={{ mt: 2 }}
          onChange={(e) => {
            setOtherRequirements(e.target.value);
          }}
        />
      )}
    </React.Fragment>
  );
}
