import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {

    let navigate = useNavigate()

    async function handleBookNow() {
        navigate('/book')
    }

    const navbar = () => {
        return (
            <Box>
                <div style={{ paddingBottom: "15px", display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                    <Typography fontSize={20} fontWeight={600} color={'white'} sx={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
                        Rattys Gardens
                    </Typography>
                    <Button
                        variant="contained"
                        endIcon={<SendIcon sx={{ width: "14px" }} />}
                        onClick={handleBookNow}
                        sx={{
                            boxShadow: "none",
                            backgroundColor: "rgba(255, 255, 255, 0.3)", // White with 0.3 opacity
                            '&:hover': {
                                backgroundColor: "rgba(255, 255, 255, 0.3)", // Set the same color for hover to maintain transparency
                            }
                        }}
                    >
                        <Typography sx={{ py: 0.4, fontSize: "13px" }}>
                            Book Now
                        </Typography>
                    </Button>

                </div>
            </Box>
        )
    }

    const services = () => {
        return (
            <Box mt={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Divider />
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={4} sx={{ textAlign: 'center' }}>
                        <Typography variant='h5'>
                            Garden Maintenance
                        </Typography>
                        <Typography variant='body2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus lobortis purus et sodales scelerisque.
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'center' }}>
                        <Typography variant='h5'>
                            Garden Maintenance
                        </Typography>
                        <Typography variant='body2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus lobortis purus et sodales scelerisque.
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'center' }}>
                        <Typography variant='h5'>
                            Garden Maintenance
                        </Typography>
                        <Typography variant='body2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus lobortis purus et sodales scelerisque.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <div style={{ height: '100vh', display: 'grid', gridTemplateRows: '1fr 1fr' }}>
            <Grid item xs={12} pt={3} px={5} style={{ position: 'relative', zIndex: 1, backgroundImage: 'url(https://images.unsplash.com/photo-1501520158826-76df880863a3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                {navbar()}
            </Grid>
            <Grid item xs={12} style={{ position: 'relative', zIndex: 2, backgroundColor: 'lightblue', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '-30px' }}>
                {services()}
            </Grid>
        </div>
    )
}

export default HomePage;
