import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Checkout from "./views/contact/Checkout";
import HomePage from "./views/home/HomePage";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/book" element={<Checkout />} />
    </Routes>
  </Router>
);

export default App;
