import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import emailjs from "@emailjs/browser";

const steps = ["Your Details", "Services", "Review"];

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);

  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [suburb, setSuburb] = React.useState(null);
  const [postcode, setPostcode] = React.useState(null);
  const [phoneNumber, setPhonenumber] = React.useState(null);

  const [checkboxState, setCheckboxState] = React.useState({});

  const [otherRequirements, setOtherRequirements] = React.useState(null);

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxState((prev) => ({
      ...prev,
      [checkboxName]: !prev[checkboxName],
    }));
  };

  const handleNext = async () => {
    if (activeStep > 1) {
      await handleSendEmail();
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  async function handleSendEmail() {
    const checkboxPayload = Object.keys(checkboxState).filter(
      (key) => checkboxState[key]
    );

    const payload = {
      to_email: "rattysgardenmaintenance@gmail.com", // Email address of the recipient
      from_name: "Website", // Your name or sender's name
      message: "This is a test email sent via EmailJS with payload.", // Email body or message
      subject: "Website Request!",
      firstName: firstName,
      lastName: lastName,
      address: `${address}, ${suburb}, ${postcode}`,
      number: phoneNumber,
      services: checkboxPayload.join(', '),
      requirements: otherRequirements
    };

    console.log(payload);

    emailjs
      .send(process.env.REACT_APP_SERVICE_ID, "template_obwb79h", payload, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      .then(
        () => {
          console.log("SUCCESS! Email sent successfully.");
        },
        (error) => {
          console.error("FAILED: ", error);
        }
      );
  }

  // process.env.REACT_APP_EMAIL_PASSWORD

  const btnDisabled = () => {
    switch (activeStep) {
      case 0:
        return !(
          firstName &&
          lastName &&
          address &&
          suburb &&
          postcode &&
          phoneNumber
        );
      case 1:
        // Add validation for the second step if needed
        return !Object.values(checkboxState).some((value) => value === true);
      case 2:
        // Add validation for the third step if needed
        return false; // Return false for now, update as per your requirements
      default:
        return true;
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            setFirstName={setFirstName}
            setLastName={setLastName}
            setAddress={setAddress}
            setSuburb={setSuburb}
            setPostcode={setPostcode}
            setPhonenumber={setPhonenumber}
          />
        );
      case 1:
        return (
          <PaymentForm
            checkboxState={checkboxState}
            setOtherRequirements={setOtherRequirements}
            handleCheckboxChange={handleCheckboxChange}
          />
        );
      case 2:
        return (
          <Review
            firstName={firstName}
            lastName={lastName}
            address={address}
            suburb={suburb}
            postcode={postcode}
            phonenumber={phoneNumber}
            checkboxState={checkboxState}
            otherRequirements={otherRequirements}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Book
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for booking.
              </Typography>
              <Typography variant="subtitle1">
                We will reach out to you shortly.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                  disabled={btnDisabled()}
                >
                  {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
}
